import {LIST_LAYOUT} from '@wix/wix-events-commons-statics'
import {callAPI, createActions} from '../../commons/actions/data-action-helper'
import {getRealEventsCount} from '../selectors/events'
import {GetState} from '../types/state'

export const CREATE_EVENT = createActions('CREATE_EVENT')
export const LOAD_EVENTS = createActions('LOAD_EVENTS')
export const SHARE_EVENT = 'SHARE_EVENT'

export const createEvent = (data: {event: wix.events.EventData; language: string}, ADI: boolean = false) =>
  callAPI(CREATE_EVENT, data, ADI)

export const shareEvent = (eventId: string, buttonName: string) => ({
  type: SHARE_EVENT,
  payload: {
    eventId,
    buttonName,
  },
})

export const loadMoreEvents = () => (dispatch, getState: GetState) => {
  const state = getState()
  const offset = getRealEventsCount(state)
  const filter = state.component.byStatus?.filter
  const {listLayout, cardsPerRow} = state.component.settings
  const limit = listLayout === LIST_LAYOUT.NEW_GRID && cardsPerRow === 3 ? 21 : 20
  const {membersEnabled, paidPlansEnabled} = getState()
  return dispatch(callAPI(LOAD_EVENTS, offset, filter, limit, membersEnabled, paidPlansEnabled))
}
