import {IWixAPI} from '@wix/native-components-infra/dist/es/src/types/types'
import {isTicketed, EVENT_CATEGORY, EVENTS, LIST, ORIGIN} from '@wix/wix-events-commons-statics'
import {AnyAction} from 'redux'
import {trackEvent} from '../../commons/utils/wix-code-api'
import {WIDGET_LOADED} from '../actions/layout'
import {RSVP_BUTTON_CLICKED} from '../actions/navigate-to-page'
import {getRealEvents} from '../selectors/events'
import {State} from '../types/state'

export const userEventsLogger = ({wixCodeApi}: {wixCodeApi: IWixAPI}) => ({getState}) => (next: Function) => (
  action: AnyAction,
) => {
  switch (action.type) {
    case WIDGET_LOADED:
      widgetLoaded(wixCodeApi, getState())
      break
    case RSVP_BUTTON_CLICKED:
      rsvpButtonClicked(wixCodeApi, action)
      break
    default:
      break
  }

  return next(action)
}

const widgetLoaded = (wixCodeApi: IWixAPI, state: State) => {
  const contents = getRealEvents(state).map(event => ({name: event.title, list: LIST}))
  trackEvent(wixCodeApi, EVENTS.CustomEvent, {
    origin: ORIGIN,
    contents,
    eventCategory: EVENT_CATEGORY,
    eventLabel: ORIGIN,
    event: EVENTS.WidgetView,
  } as any)
}

const rsvpButtonClicked = (wixCodeApi: IWixAPI, action: AnyAction) => {
  const clickedEvent = action.payload.event

  if (isTicketed(clickedEvent)) {
    trackEvent(wixCodeApi, EVENTS.ClickProduct, {origin: ORIGIN, name: clickedEvent.title, list: LIST})
  } else {
    trackEvent(wixCodeApi, EVENTS.CustomEvent, {
      event: 'Name:rsvpClick',
      name: clickedEvent.title,
      eventCategory: EVENT_CATEGORY,
      eventLabel: ORIGIN,
    } as any)
  }
}
