import {AnyAction} from 'redux'
import {INJECT_COMPONENT_DRAFT, UPDATE_COMPONENT_TEXTS, UPDATE_STYLE_PARAMS} from '../actions/component'
import {State} from '../types/state'

const defaultState: WidgetComponentConfig = {} as WidgetComponentConfig

export const component = (state = defaultState, action: AnyAction): WidgetComponentConfig => {
  switch (action.type) {
    case UPDATE_STYLE_PARAMS:
      return {
        ...state,
        settings: {
          ...state.settings,
          ...action.payload.numbers,
          ...action.payload.booleans,
        },
        params: {
          ...action.payload.numbers,
          ...action.payload.booleans,
        },
      }
    case INJECT_COMPONENT_DRAFT:
      if (!action.newest) {
        return state
      }

      return {
        ...state,
        byEventId: action.payload.component.config.byEventId,
        byStatus: action.payload.component.config.byStatus,
      }
    case UPDATE_COMPONENT_TEXTS:
      return {
        ...state,
        settings: {
          ...state.settings,
          texts: action.payload,
        },
      }
    default:
      return state
  }
}

export const getComponentEventIds = (state: State) => state.component.byEventId?.eventId
